import '../css/app.css';

import 'lazysizes';
import Alpine from 'alpinejs';
import baguetteBox from 'baguettebox.js';
import { Splide } from '@splidejs/splide';

window.Alpine = Alpine;

Alpine.data('counter', () => ({
  count: 0,
  fetch() {
    fetch('/api', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
          query CountQuery {
            entryCount(section: "news")
          }
          `,
        variables: {},
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        this.count = result.data.entryCount;
        console.log(result.data.entryCount);
      });
  },
}));

Alpine.data('video', () => ({
  currentTime: 0,
  seekTo(time) {
    this.$refs.video.currentTime = time;
  },
}));

Alpine.start();

baguetteBox.run('.baguette', {
  noScrollbars: true,
  async: true,
});

document.addEventListener('DOMContentLoaded', function () {
  const elmsGallery = document.getElementsByClassName('splide-gallery');
  for (let i = 0, len = elmsGallery.length; i < len; i++) {
    new Splide(elmsGallery[i], {
      type: 'loop',
      lazyLoad: 'nearby',
      perPage: 1,
      perMove: 1,
      gap: 0,
      pagination: true,
      autoplay: false,
      arrowPath:
        'm15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z',
    }).mount();
  }
});
